export const signup = {
  'Already have an account? <1>Sign in!</1>':
    'Ya tienes una cuenta? <1>Acceso!</1>',
  'And live your life free of hairs!': 'Y vive una vida libre de vellitos!',
  'Confirm password': 'Confirmar contraseña',
  Email: 'Correo electrónico',
  Fullname: 'Nombre completo',
  'I accept the <1>Terms and conditions</1> and I authorize the use of my data according to the <3>Privacy policy</3>':
    'Acepto los <1>Términos y condiciones</1> y autorizo el uso de mis datos de acuerdo con la <3>Declaracion de privacidad</3>.',
  Medium: 'Media',
  Password: 'Contraseña',
  Phone: 'Teléfono',
  'Please, inform a valid e-mail': 'Por favor, libere un email válido',
  'Please, inform a valid name': 'Por favor, libere un nombre válido',
  'Please, inform a valid whatsapp number':
    'Por favor, informe um número válido de whatsapp',
  'Or register with your social networks': 'O regístrate en tus redes sociales',
  Register: 'Registrar',
  'Register yourself': 'Registrate',
  'Registered successfully!': '¡Registrado con éxito!',
  "The passwords don't match": 'Las contraseñas no coinciden',
  'This field is required': 'Este campo es obligatorio',
  'Too weak': 'Muy débil',
  'Sign up': 'Registro',
  'Sign up with Google': 'Registrar con Google',
  Strong: 'Fuerte',
  Weak: 'Débil',
  'How should I create my password?': '¿Cómo debo crear mi contraseña?',
};
