import { lazy } from 'react';

import RecommenderRedirection from 'modules/landingPages/presentation/pages/RecommenderRedirection';
import type { TRoute } from 'shared/presentation/modules/definitions';

const Benefit = lazy(
  () => import('modules/landingPages/presentation/pages/Benefit'),
);
const FluencyLP = lazy(
  () => import('modules/landingPages/presentation/pages/FluencyLP'),
);
const LeadViaRecommendation = lazy(
  () => import('modules/landingPages/presentation/pages/LeadViaRecommendation'),
);
const LightningOffer = lazy(
  () => import('modules/landingPages/presentation/pages/LightningOffer'),
);
const MothersDay = lazy(
  () => import('modules/landingPages/presentation/pages/MothersDay'),
);
const RecommenderIdentification = lazy(
  () =>
    import('modules/landingPages/presentation/pages/RecommenderIdentification'),
);
const ThreeSessionsGift = lazy(
  () => import('modules/landingPages/presentation/pages/ThreeSessionsGift'),
);
const TotemQRCodeRecommendation = lazy(
  () =>
    import('modules/landingPages/presentation/pages/TotemQRCodeRecommendation'),
);

const routes: TRoute[] = [
  {
    type: 'route',
    path: '/depilacao-a-laser-promocao',
    component: LightningOffer,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/voce-ganhou',
    component: ThreeSessionsGift,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/has-ganado-3-sesiones',
    component: ThreeSessionsGift,
    disabledTenancies: ['es', 'pt'],
  },
  {
    type: 'route',
    path: '/lp-fluency',
    component: FluencyLP,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/indicacao/:hash',
    component: LeadViaRecommendation,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/indicacao-mae',
    component: MothersDay,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/beneficio-indicacao',
    component: Benefit,
    disabledTenancies: ['es-CR'],
  },
  {
    type: 'route',
    path: '/indicador',
    component: RecommenderIdentification,
    disabledTenancies: ['es-CR'],
  },
  {
    type: 'route',
    path: '/compartilhe-e-ganhe',
    component: RecommenderRedirection,
  },
  {
    type: 'route',
    path: '/indique-e-ganhe',
    component: RecommenderRedirection,
  },
  {
    type: 'route',
    path: '/sorteiototem',
    component: TotemQRCodeRecommendation,
    disabledTenancies: ['es', 'es-CR'],
  },
];

export default routes;
