import { makeCustomerPasswordsRepository } from 'modules/authentication/infra/repositories';

import UpdatePasswordUseCase from './implementations/UpdatePasswordUseCase';

let instance: UpdatePasswordUseCase | null = null;

export default function makeUpdatePasswordUseCase() {
  if (!instance) {
    const repository = makeCustomerPasswordsRepository();

    instance = new UpdatePasswordUseCase(repository);
  }

  return instance;
}
