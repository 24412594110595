import { IUpdatePasswordDTO } from 'modules/authentication/domain/dtos';
import { ICustomerPasswordsRepository } from 'modules/authentication/domain/repositories';

export default class UpdatePasswordUseCase {
  constructor(
    private customersPasswordRepository: ICustomerPasswordsRepository,
  ) {}

  public async execute(params: IUpdatePasswordDTO) {
    return this.customersPasswordRepository.update(params);
  }
}
