const ErrorHandler = error => {
  let message = 'Erro ao comunicar com o servidor';
  if (!error || !error.response) {
    return message;
  }

  const { data } = error.response;

  if (data?.errors !== undefined) {
    const errors = [];

    Object.keys(data.errors).forEach(key => {
      errors.push(data.errors[key].join(', '));
    });

    message = errors.join(', ');
  } else if (data.message) {
    message = data.message;
  } else if (typeof data === 'string') {
    message = data;
  }

  if (message.includes('cart_expiration')) {
    return 'Cartão expirado';
  }

  if (message.includes('card_number')) {
    return 'Número do cartão ou bandeira inválido';
  }

  if (message.includes('payment_date')) {
    return 'Não foi possível alterar a data de cobrança';
  }

  return message;
};

export default ErrorHandler;
