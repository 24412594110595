export const updatePassword = {
  'Update password': 'Atualizar senha',
  '{{name}}, this is your link to update your password, <2>do not share it with anyone</2>':
    '{{name}}, este é o seu link para você atualizar a sua senha, <2>não o compartilhe com ninguém.</2>',
  'Available for only {{time}}': 'Link válido por apenas {{time}}.',

  'You must provide a password following all the requirements bellow':
    'Você deve informar uma senha que siga todos os requisitos abaixo',
  'The passwords are not the same': 'As senhas não são iguais',
  'You must provide a password': 'Você deve informar uma senha',

  'It must contain more than 8 characters': 'Deve conter mais de 8 caracteres',
  'It must contain at least one lower case letter':
    'Deve conter pelo menos uma letra minúscula',
  'It must contain at least one upper case letter':
    'Deve conter pelo menos uma letra maiúscula',
  'It must contain at least one number': 'Deve conter pelo menos um número',
  'It must contain at least one special character':
    'Deve conter pelo menos um caractere especial',

  'Success!': 'Sucesso!',
  'Your password was updated successfully! Now you can login into your customer area with the new password created':
    'Sua senha foi atualizada com sucesso! Agora você pode logar na sua área do cliente com sua nova senha criada.',
  'Go home': 'Ir para o início',
  Login: 'Fazer meu login',

  'Link unavailable!': 'Link indisponível!',
  'This link is unavailable Please, try to get a new one':
    'Este link está indisponível. Por favor, tente conseguir um novo link.',

  'Link expired!': 'Link expirado!',
  'This link is expired Please, try to get a new one':
    'Este link está expirado. Por favor, tente conseguir um novo link.',
};
