import { ICustomerPasswordsRepository } from 'modules/authentication/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisCustomerPasswordsRepository from './implementations/VectisCustomerPasswordsRepository';

let instance: ICustomerPasswordsRepository | null = null;

export default function makeCustomerPasswordsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisCustomerPasswordsRepository(httpProvider);
  }

  return instance;
}
