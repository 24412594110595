import { lazy } from 'react';

import type { TRoute } from 'shared/presentation/modules/definitions';

const Login = lazy(
  () => import('modules/authentication/presentation/pages/Login'),
);
const PasswordRecovery = lazy(
  () => import('modules/authentication/presentation/pages/PasswordRecovery'),
);
const SignUp = lazy(
  () => import('modules/authentication/presentation/pages/SignUp'),
);
const UpdatePasswordLink = lazy(
  () => import('modules/authentication/presentation/pages/UpdatePasswordLink'),
);

const routes: TRoute[] = [
  {
    type: 'route',
    path: '/recuperacao-de-senha',
    authentication: 'public_only',
    component: PasswordRecovery,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/registrar',
    authentication: 'public_only',
    component: SignUp,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/login',
    authentication: 'public_only',
    component: Login,
    disabledTenancies: ['es', 'es-CR'],
  },
  {
    type: 'route',
    path: '/atualizar-senha/:hash',
    authentication: 'public_only',
    component: UpdatePasswordLink,
  },
];

export default routes;
