import { ICustomerPasswordsRepository } from 'modules/authentication/domain/repositories';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';

export default class VectisCustomerPasswordsRepository
  implements ICustomerPasswordsRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public update: ICustomerPasswordsRepository['update'] = async params => {
    await this.httpProvider.post(
      `/clientes/${params.customerId}/definirSenha`,
      {
        senha: params.password,
      },
    );
  };
}
