import { useEffect, useState } from 'react';

import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { makeHTTPProvider } from 'shared/infra/providers';

import WhereToFindList from './WhereToFindList';
import { mapUnits } from '../../constants/mapUnits';
import ErrorHandler from '../../helpers/ErrorHandler';
import CircularLoading from '../CircularLoading';
import RoundSelect from '../RoundSelect';

const useStyles = makeStyles(theme => ({
  spacingTop: {
    marginTop: '0.2rem',
  },
  item: {
    color: theme.palette.text.primary,
  },
}));

const radians = value => {
  return (value * Math.PI) / 180;
};

const reduceUnits = states => {
  return Object.values(states)
    .reduce((cities, state) => [...cities, ...Object.values(state.cities)], [])
    .reduce((units, city) => [...units, ...city.units], []);
};

const getRadiusClinics = (u, lat, long) => {
  return reduceUnits(u).filter(unit => {
    return (
      6371 *
        Math.acos(
          Math.cos(radians(lat)) *
            Math.cos(radians(unit.latitude)) *
            Math.cos(radians(unit.longitude) - radians(long)) +
            Math.sin(radians(lat)) * Math.sin(radians(unit.latitude)),
        ) <=
      10
    );
  });
};

const getUnits = () => {
  return makeHTTPProvider()
    .get('/units')
    .then(response => {
      if (response.length === 0) {
        return {
          units: {},
          uf: null,
          city: null,
          unitInMap: null,
        };
      }

      const tmpUnits = {};
      for (let index = 0; index < response.length; index++) {
        const u = response[index];

        if (!tmpUnits[u.uf]) {
          tmpUnits[u.uf] = { uf: u.uf, cities: {} };
        }

        if (!tmpUnits[u.uf].cities[u.cidade]) {
          tmpUnits[u.uf].cities[u.cidade] = {
            city: u.cidade,
            units: [],
          };
        }

        tmpUnits[u.uf].cities[u.cidade].units.push(u);
      }

      let unit;

      const unitSp = tmpUnits.SP?.cities['São Paulo'].units.find(cityUnit =>
        cityUnit.nomeFantasia.includes('São Judas'),
      );

      if (unitSp) {
        unit = unitSp;
      } else {
        const { cities } = tmpUnits[Object.keys(tmpUnits)[0]];

        // eslint-disable-next-line prefer-destructuring
        unit = cities[Object.keys(cities)[0]].units[0];
      }

      return {
        units: tmpUnits,
        uf: unit.uf,
        city: unit.cidade,
        unitInMap: unit,
      };
    });
};

const nearestUnits = async (units, lat, lng) => {
  if (lat || lng) {
    const clinicsInRadius = getRadiusClinics(units, lat, lng);
    const [nearest] = clinicsInRadius;

    return {
      units,
      uf: nearest ? nearest.uf : mapUnits.uf,
      city: nearest ? nearest.cidade : mapUnits.cidade,
      unitInMap: nearest || mapUnits,
    };
  }

  return units;
};

export default function WhereToFindSelector({
  onUnitsLoaded,
  onUnitSelected,
  initialUnitMap,
}) {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  const [uf, setUf] = useState('');
  const [city, setCity] = useState();

  const [loading, setLoading] = useState(true);

  const [units, setUnits] = useState([]);
  const [unitInMap, setUnitInMap] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  function handleError(e) {
    const errorMsg = ErrorHandler(e);
    enqueueSnackbar(t(errorMsg), {
      variant: 'error',
    });
  }

  const handleUnitsResult = result => {
    const params = new URLSearchParams(location.search);
    const clinicUf = params.get('clinicUf');

    if (clinicUf) {
      setUnits(result.units);
      setUf(clinicUf);
      setUnitInMap(result.unitInMap);
      setLoading(false);
      onUnitsLoaded(reduceUnits(result.units));
      return;
    }

    setUnits(result.units);
    setUf(result.uf);
    setCity(result.city);
    setUnitInMap(result.unitInMap);

    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        nearestUnits(result.units, latitude, longitude)
          .then(r => {
            setUnits(r.units);
            setUf(r.uf);
            setCity(r.city);
            setUnitInMap(r.unitInMap);
            onUnitsLoaded(reduceUnits(r.units));
          })
          .catch(handleError)
          .finally(() => setLoading(false));
      },
      err => {
        console.error(err);
        setLoading(false);
        onUnitsLoaded(reduceUnits(result.units));
        initialUnitMap(result.unitInMap);
      },
      {
        timeout: 5000,
      },
    );
    // setUnitInMap(result.unitInMap);
  };

  useEffect(() => {
    getUnits()
      .then(handleUnitsResult)
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!uf && city) return;

    const ufUnit = units[uf];

    if (!ufUnit) return;

    const cityUnit = ufUnit.cities[city];

    if (!cityUnit) return;

    const [unit] = cityUnit.units;

    setUnitInMap(unit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  useEffect(() => {
    onUnitSelected(unitInMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitInMap]);

  const currentCity =
    units && units[uf] && units[uf].cities ? units[uf].cities[city] : null;

  return loading ? (
    <CircularLoading />
  ) : (
    <>
      <Grid item xs={12} md={10} container justifyContent="center" spacing={2}>
        <Grid item xs={6}>
          <RoundSelect
            fullWidth
            id="uf"
            name="uf"
            value={uf}
            className={classes.spacingTop}
            onChange={e => {
              e.preventDefault();
              const { value } = e.target;
              const [key] = Object.keys(units[value].cities);
              setUf(value);
              setCity(units[value].cities[key].city);
              // setUnitInMap(units[uf].cities[key].units[0]);
            }}
          >
            {Object.keys(units).map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem
                key={`${index}_${item.id}`}
                value={item}
                className={classes.item}
              >
                {item}
              </MenuItem>
            ))}
          </RoundSelect>
        </Grid>
        <Grid item xs={6}>
          <RoundSelect
            fullWidth
            id="city"
            name="city"
            value={(currentCity && currentCity.city) || ''}
            className={classes.spacingTop}
            onChange={e => {
              e.preventDefault();
              const { value } = e.target;
              setCity(value);
            }}
          >
            {units[uf] &&
              Object.keys(units[uf].cities).map(item => (
                <MenuItem key={item} value={item} className={classes.item}>
                  {item}
                </MenuItem>
              ))}
          </RoundSelect>
        </Grid>
      </Grid>
      <WhereToFindList
        units={units}
        uf={uf}
        city={currentCity}
        unitInMap={unitInMap}
        setUnitInMap={setUnitInMap}
      />
    </>
  );
}
