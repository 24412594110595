export const updatePassword = {
  'Update password': 'Actualizar contraseña',
  '{{name}}, this is your link to update your password, <2>do not share it with anyone</2>':
    '{{name}}, este es tu enlace para actualizar tu contraseña, <2>no lo compartas con nadie.</2>',
  'Available for only {{time}}': 'Enlace válido sólo por {{time}}.',

  'You must provide a password following all the requirements bellow':
    'Debe ingresar una contraseña que cumpla con todos los requisitos a continuación',
  'The passwords are not the same': 'Las contraseñas no son las mismas',
  'You must provide a password': 'Debes ingresar una contraseña',

  'It must contain more than 8 characters': 'Debe contener más de 8 caracteres',
  'It must contain at least one lower case letter':
    'Debe contener al menos una letra minúscula',
  'It must contain at least one upper case letter':
    'Debe contener al menos una letra mayúscula',
  'It must contain at least one number': 'Debe contener al menos un número',
  'It must contain at least one special character':
    'Debe contener al menos un carácter especial',

  'Success!': 'Éxito!',
  'Your password was updated successfully! Now you can login into your customer area with the new password created':
    'Su contraseña ha sido actualizada exitosamente! Ya puedes iniciar sesión en tu área de cliente con tu nueva contraseña creada.',
  'Go home': 'Ir al inicio',
  Login: 'Acceso',

  'Link unavailable!': '¡Enlace no disponible!',
  'This link is unavailable Please, try to get a new one':
    'Este enlace no está disponible. Intente obtener uno nuevo.',

  'Link expired!': '¡Enlace expirado!',
  'This link is expired Please, try to get a new one':
    'Este enlace ha expirado. Intente obtener uno nuevo.',
};
