import { ICustomer } from 'shared/domain/entities';

export type TVectisCustomerStatus =
  | 'CLIENTE'
  | 'LEAD'
  | 'SUSPECT'
  | 'PROSPECT'
  | 'OUT';

export interface IVectisCustomer {
  id: number;
  nome: string;
  sobrenome?: string;
  nomeCompleto?: string;
  foneCelular?: string;
  sexo?: 'FEMININO' | 'MASCULINO';
  cpf?: string;
  cpfCnpj?: string;
  email?: string;
  statusMaiorIdade: 'MAIOR_IDADE' | 'MENOR_16' | 'MENOR_IDADE';
  rg?: string;
  numeroRg?: string;
  dataNascimento?: string;
  cep?: string;
  numeroCep?: string;
  complemento?: string;
  cidade?: string;
  idCidade?: number;
  bairro?: string;
  idBairro?: number;
  numeroDocumentoFiscal?: string;
  logradouro?: string;
  numeroLogradouro?: string;
  hash?: string;
  cuponsBonificacao?: number[];
  responsavel?: {
    nome?: string;
    sobrenome?: string;
    email?: string;
    cpfCnpj?: string;
    cpf?: string;
    rg?: string;
    numeroRg?: string;
    dataNascimento?: string;
    cep?: string;
    numeroCep?: string;
    cidade?: string;
    idCidade?: number;
    bairro?: string;
    idBairro?: number;
    numeroDocumentoFiscal?: string;
    logradouro?: string;
    numeroLogradouro?: string;
    foneCelular?: string;
    complemento?: string;
  };

  statusCliente: TVectisCustomerStatus;
  status?: TVectisCustomerStatus;
  flAtivo: 'S' | 'N';
  dataCadastro?: string;
  dataUltimoContato?: string;
  quantidadeTotalBonificacoes?: number;
  whatsappValido: boolean;
}

const API_GENDER_DICT: Record<
  NonNullable<IVectisCustomer['sexo']>,
  ICustomer['gender']
> = {
  FEMININO: 'FEMALE',
  MASCULINO: 'MALE',
};

const MINORITY_BY_AGE_STATUS: Record<
  IVectisCustomer['statusMaiorIdade'],
  ICustomer['minorityStatus']
> = {
  MAIOR_IDADE: 'ADULT',
  MENOR_IDADE: 'TEEN',
  MENOR_16: 'MINOR',
};

const STATUS_TRANSLATION: Record<TVectisCustomerStatus, ICustomer['status']> = {
  CLIENTE: 'CUSTOMER',
  LEAD: 'LEAD',
  PROSPECT: 'PROSPECT',
  SUSPECT: 'SUSPECT',
  OUT: 'OUT',
};

type TBuildAddress = {
  bairro?: string;
  cep?: string;
  numeroCep?: string;
  cidade?: string;
  idBairro?: number;
  idCidade?: number;
  logradouro?: string;
  numeroLogradouro?: string;
  complemento?: string;
};

type TBuildFullName = {
  firstName: string;
  lastName?: string;
  fullName?: string;
};

export class Customer implements ICustomer {
  public id: ICustomer['id'];
  public firstName: ICustomer['firstName'];
  public lastName: ICustomer['lastName'];
  public fullName: ICustomer['fullName'];
  public phone: ICustomer['phone'];
  public gender: ICustomer['gender'];
  public minorityStatus: ICustomer['minorityStatus'];
  public document: ICustomer['document'];
  public alternativeDocument: ICustomer['alternativeDocument'];
  public birthdate: ICustomer['birthdate'];
  public email: ICustomer['email'];
  public address: ICustomer['address'];
  public guardian: ICustomer['guardian'];
  public idHash: ICustomer['idHash'];
  public promotions: ICustomer['promotions'];
  public status: ICustomer['status'];
  public isActive: ICustomer['isActive'];
  public createdAt: ICustomer['createdAt'];
  public lastContact: ICustomer['lastContact'];
  public totalBonuses: ICustomer['totalBonuses'];
  public validWhatsapp: ICustomer['validWhatsapp'];

  constructor({
    id,
    nome,
    sobrenome,
    nomeCompleto,
    foneCelular,
    sexo,
    cpf,
    cpfCnpj,
    statusMaiorIdade,
    bairro,
    cep,
    numeroCep,
    complemento,
    cidade,
    dataNascimento,
    idBairro,
    idCidade,
    numeroDocumentoFiscal,
    responsavel,
    email,
    rg,
    numeroRg,
    logradouro,
    numeroLogradouro,
    hash,
    status,
    statusCliente,
    flAtivo,
    dataCadastro,
    dataUltimoContato,
    quantidadeTotalBonificacoes = 0,
    cuponsBonificacao = [],
    whatsappValido,
  }: IVectisCustomer) {
    this.id = id;
    this.firstName = nome;
    this.lastName = sobrenome || '';
    this.fullName = this.buildFullName({
      firstName: nome,
      lastName: sobrenome,
      fullName: nomeCompleto,
    });
    this.phone = foneCelular;
    this.gender = sexo && API_GENDER_DICT[sexo];
    this.document = cpf || cpfCnpj;
    this.alternativeDocument = rg || numeroRg || numeroDocumentoFiscal;
    this.minorityStatus = MINORITY_BY_AGE_STATUS[statusMaiorIdade];
    this.birthdate = dataNascimento;
    this.email = email;
    this.promotions = cuponsBonificacao;
    this.address = this.buildAddress({
      bairro,
      cep,
      numeroCep,
      cidade,
      idBairro,
      idCidade,
      logradouro,
      numeroLogradouro,
      complemento,
    });
    this.guardian = this.buildGuardian(responsavel);
    this.idHash = hash;

    this.isActive = flAtivo === 'S';
    const statusField = status || statusCliente;
    this.status = STATUS_TRANSLATION[statusField];
    this.createdAt = dataCadastro;
    this.lastContact = dataUltimoContato;
    this.totalBonuses = quantidadeTotalBonificacoes;
    this.validWhatsapp = whatsappValido;
  }

  private buildFullName({ firstName, fullName, lastName }: TBuildFullName) {
    if (fullName) return fullName;

    if (!lastName) return firstName;

    return `${firstName} ${lastName}`;
  }

  private buildAddress({
    bairro,
    cep,
    numeroCep,
    cidade,
    idBairro,
    idCidade,
    logradouro,
    numeroLogradouro,
    complemento,
  }: TBuildAddress) {
    const address: ICustomer['address'] = {
      postalCode: cep || numeroCep,
      street: logradouro,
      number: numeroLogradouro,
      additionalInformation: complemento,
    };

    if (idCidade) {
      address.city = {
        id: idCidade,
        name: cidade,
      };
    }
    if (idBairro) {
      address.district = {
        id: idBairro,
        name: bairro,
      };
    }

    const hasNoValidValues = Object.values(address).every(value => !value);
    if (hasNoValidValues) return;

    return address;
  }

  private buildGuardian(
    guardian: IVectisCustomer['responsavel'],
  ): ICustomer['guardian'] {
    if (!guardian) return;

    const {
      cep,
      numeroCep,
      bairro,
      cidade,
      cpfCnpj,
      cpf,
      dataNascimento,
      email,
      idBairro,
      idCidade,
      nome,
      numeroDocumentoFiscal,
      rg,
      numeroRg,
      sobrenome,
      logradouro,
      numeroLogradouro,
      complemento,
      foneCelular,
    } = guardian;

    return {
      firstName: nome,
      lastName: sobrenome,
      document: cpfCnpj || cpf,
      alternativeDocument: rg || numeroRg || numeroDocumentoFiscal,
      birthdate: dataNascimento,
      email,
      phone: foneCelular,
      address: this.buildAddress({
        complemento,
        bairro,
        cep: cep || numeroCep,
        cidade,
        idBairro,
        idCidade,
        logradouro,
        numeroLogradouro,
      }),
    };
  }
}
