export const schedules = {
  'Are you sure you want to cancel the session?':
    'Estás seguro de que quieres cancelar la sesión?',
  'Cancel session': 'Cancelar sesión',
  '{{date}} at {{time}}': '{{date}} a las {{time}}',
  Finished: 'Concluido',
  'Inform the reason for the cancellation':
    'Informe el motivo de la cancelación',
  'Next Sessions': 'Próximas sesiones',
  Reschedule: 'Reprogramar',
  Schedule: 'Programar',
  Schedules: 'Citas',
  'Schedules History': 'Historial de Citas',
  Today: 'Hoy',
  Tomorrow: 'Mañana',
  'View your schedules, reeschedule or appoint your new sessions':
    'Vea sus citas, reprograme o reserve su próxima sesión',
  "You don't have any past schedules": 'No tienes citas previas',
};
