import React, { ReactElement, useState } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import { useMediaQuery } from 'shared/presentation/hooks';

import * as S from './styles';

interface ITooltipProps {
  asChild?: boolean;
  content: ReactElement;
}

const DELAY = 200;

const Tooltip: React.FC<ITooltipProps> = ({ asChild, content, children }) => {
  const isSm = useMediaQuery('sm');

  const [open, setOpen] = useState<boolean>(false);

  return (
    <RadixTooltip.Provider delayDuration={DELAY}>
      <RadixTooltip.Root open={isSm ? open : undefined}>
        <S.Trigger
          asChild={asChild}
          type="button"
          onClick={() => {
            if (isSm) setOpen(!open);
          }}
        >
          {children}
        </S.Trigger>

        <RadixTooltip.Portal>
          <S.Content>
            <S.Arrow />

            {content}
          </S.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
